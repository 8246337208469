$background-color: #000000;
$text-color: #fff;
$text-font: "Poppins";
$text-size: 16px;
html,
body {
    width: 100%;
    min-height: 100%;
    margin: 0;
    padding: 0;
    font: $text-size $text-font;
    font-weight: normal;
    color: $text-color;
    -webkit-font-smoothing: antialiased;
    background: $background-color;
}

.lazy {
    transition: filter 0.1s;
    will-change: filter;
}

.lazy:not(.loaded) {
    filter: blur(50px);
}

a {
    color: $text-color;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Bold.eot");
    src: url("../assets/fonts/Poppins-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/Poppins-Bold.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Bold.woff") format("woff"),
        url("../assets/fonts/Poppins-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-SemiBold.eot");
    src: url("../assets/fonts/Poppins-SemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/Poppins-SemiBold.woff2") format("woff2"),
        url("../assets/fonts/Poppins-SemiBold.woff") format("woff"),
        url("../assets/fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../assets/fonts/Poppins-Regular.eot");
    src: url("../assets/fonts/Poppins-Regular.eot?#iefix")
            format("embedded-opentype"),
        url("../assets/fonts/Poppins-Regular.woff2") format("woff2"),
        url("../assets/fonts/Poppins-Regular.woff") format("woff"),
        url("../assets/fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: linear-gradient($background-color 0%, transparent 100%);
    &-inner {
        max-width: 1300px;
        margin: 0 auto;
        padding: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1100px) {
            padding: 0 0 0 16px;
        }
    }

    &-logo {
        display: block;
        position: relative;
        z-index: 11;
        &-image {
            display: block;
            @media screen and (max-width: 1100px) {
                width: 110px;
            }
        }
    }

    &-nav {
        @media screen and (max-width: 1100px) {
            position: fixed;
            top: 0;
            left: 0;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            width: 100%;
            transform: translateX(100%);
            transition: transform 0.3s;
            background: $background-color;
            flex-direction: column;
            z-index: 10;
            padding-top: 100px;
            display: none;
            box-sizing: border-box;
        }
        &-link {
            text-decoration: none;
            padding: 0 25px;
            display: inline-block;
            position: relative;
            @media screen and (max-width: 1100px) {
                padding: 8px 16px;
                font-size: 18px;
            }
            &:after {
                content: "";
                display: block;
                width: 100%;
                position: absolute;
                top: -70px;
                left: 0;
                height: 10px;
                background: #fff;
                filter: blur(10px);
                transition: top 0.3s;
                @media screen and (max-width: 1100px) {
                    display: none;
                }
            }
            &:hover:after {
                top: -50px;
            }
            &.active {
                font-weight: bold;
            }
            &-label {
                color: red;
                top: -1em;
            }
        }
        .header-button {
            position: absolute;
            bottom: 16px;
            left: 16px;
            right: 16px;
        }
        &-handler {
            display: none;
            @media screen and (max-width: 1100px) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 64px;
                height: 52px;
                color: #003060;
                padding: 0;
                outline: 0;
                border: 0;
                background: transparent;
                cursor: pointer;
                z-index: 11;
                margin: auto;
                margin-right: 0;
                &-icon {
                    position: relative;
                    display: block;
                    font-size: 24px;
                    width: 1em;
                    height: 2px;
                    background-image: linear-gradient(#fff, #fff);
                    background-repeat: no-repeat;
                    transform: scale(1);
                    background-size: 100% 100%;
                    will-change: transform, background-size;
                    transition: transform 0.2s, background-size 0.2s;
                }
                &-icon:active {
                    transform: scale(0.9);
                }
                &-icon:before,
                &-icon:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    background-image: inherit;
                    border-radius: inherit;
                    will-change: inherit;
                    transition: inherit;
                }
                &-icon:before {
                    transform: translateY(-0.3em) rotate(0);
                }
                &-icon:after {
                    transform: translateY(0.3em) rotate(0);
                }
                body.nav-visible &-icon {
                    background-size: 0% 100%;
                    background-image: linear-gradient(#fff, #fff);
                }
                body.nav-visible &-icon:before {
                    transform: translateY(0) rotate(45deg);
                }
                body.nav-visible &-icon:after {
                    transform: translateY(0) rotate(-45deg);
                }
            }
        }
    }
    @media screen and (max-width: 1100px) {
        body.loaded &-nav {
            display: flex;
        }
    }
    body.nav-visible &-nav {
        transform: translateX(0);
    }

    &-button {
        min-width: 210px;
        padding: 18px 24px 17px 24px;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        backdrop-filter: blur(10px);
        will-change: transform, box-shadow;
        transition: transform 0.3s, box-shadow 0.3s;
        &:hover {
            transform: scale(1.0625);
            box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
        }
        &:active {
            transform: scale(0.99);
        }

        @media screen and (max-width: 1100px) {
            display: inline-block; /* Make the button visible on mobile */
            min-width: 100px; /* Adjust the minimum width for mobile */
            padding: 12px 18px; /* Adjust padding to reduce overall button size */
            font-size: 14px; /* Optionally adjust the font size for smaller displays */
            margin: 10px 16px; /* Adjust margins as needed for mobile layout */
        }
    }
    /* Ensure the burger menu is not displayed on mobile */
    &-nav-handler {
        @media screen and (max-width: 1100px) {
            display: none; /* Hide the burger menu on mobile */
        }
    }
}

.intro {
    position: relative;
    overflow: hidden;
    height: 100vh;
    height: calc(var(--ivh, 1vh) * 100);
    min-height: 660px;
    transition: height 0.3s;
    &-loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 100;
        transition: all 0.3s;
    }
    body.loaded &-loader {
        visibility: hidden;
        opacity: 0;
        transform: scale(2, 0.001);
        filter: blur(30px);
    }
    &-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        margin: auto;
        top: 0;
        background: linear-gradient(180deg, #000000 0%, #08022f 50%);
        @media screen and (max-width: 900px) {
            width: 200%;
            left: -50%;
        }
        @keyframes intro-gradient-1 {
            20% {
                transform: scale(1.2);
            }
            40% {
                transform: scale(1.1) skewX(20deg);
            }
            60% {
                transform: scale(1.1);
            }
            80% {
                transform: scale(1.2) skewZ(20deg);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes intro-gradient-2 {
            20% {
                transform: scale(1.2);
            }
            40% {
                transform: scale(1.1) skewZ(-30deg);
            }
            60% {
                transform: scale(1.1);
            }
            80% {
                transform: scale(1.2) skewZ(30deg);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes intro-gradient-3 {
            20% {
                transform: scale(1.2);
            }
            40% {
                transform: scale(1.1) skewX(-20deg);
            }
            60% {
                transform: scale(1.1);
            }
            80% {
                transform: scale(1.2) skewZ(-20deg);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes intro-gradient-4 {
            20% {
                transform: scale(1.2);
            }
            40% {
                transform: scale(1.1) translateY(-20%);
            }
            60% {
                transform: scale(1.1);
            }
            80% {
                transform: scale(1.2) translateY(-20%);
            }
            100% {
                transform: scale(1);
            }
        }
        @keyframes intro-gradient-init {
            0% {
                opacity: 0;
                filter: blur(1rem);
                transform: scale(5, 0.001);
            }
            100% {
                opacity: 1;
                transform: none;
                filter: none;
            }
        }
        &-gradient {
            will-change: transform;
            opacity: 0;
            &:nth-child(1) {
                display: block;
                position: absolute;
                width: 63.4%;
                height: 64.5%;
                right: 0;
                top: 14.2%;
            }
            &:nth-child(2) {
                display: block;
                position: absolute;
                width: 57%;
                height: 44%;
                left: 0;
                right: 0;
                margin: auto;
                top: 25.3%;
            }
            &:nth-child(3) {
                display: block;
                position: absolute;
                width: 63.4%;
                height: 64.5%;
                left: 13.8%;
                top: 14.2%;
            }
            &:nth-child(4) {
                display: block;
                position: absolute;
                width: 72.6%;
                height: 77.9%;
                left: 0;
                top: 9.8%;
            }
        }
        body.loaded &-gradient {
            opacity: 1;
            &:nth-child(1) {
                animation: intro-gradient-1 40s 3s infinite,
                    intro-gradient-init 3s;
            }
            &:nth-child(2) {
                animation: intro-gradient-2 60s 3s infinite,
                    intro-gradient-init 3s;
            }
            &:nth-child(3) {
                animation: intro-gradient-3 20s 3s infinite,
                    intro-gradient-init 3s;
            }
            &:nth-child(4) {
                animation: intro-gradient-4 40s 3s infinite,
                    intro-gradient-init 3s;
            }
        }
    }
    &-inner {
        max-width: 930px;
        height: 100%;
        margin: auto;
        padding: 48px 16px;
        text-align: center;
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        @media screen and (max-width: 900px) {
            max-width: 320px;
        }
        &:before {
            content: "";
        }
    }
    &-wrapper {
        //flex: 1;
    }
    &-title {
        font-weight: 700;
        font-size: 60px;
        line-height: 85px;
        text-align: center;
        text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
        opacity: 0;
        transform: translateY(50px) scale(0.95);
        @media screen and (max-width: 900px) {
            font-size: 56px;
            line-height: 60px;
            margin-bottom: 24px;
        }
    }
    body.loaded &-title {
        transition: opacity 3s 0.5s, transform 3s 0.5s;
        will-change: opacity, transform;
        opacity: 1;
        transform: none;
    }
    &-subtitle {
        font-weight: 700;
        font-size: 20px;
        text-align: center;
        opacity: 0;
        transform: translateY(50px) scale(0.95);
        @media screen and (max-width: 900px) {
            font-size: 16px;
        }
    }
    body.loaded &-subtitle {
        transition: opacity 3s 0.7s, transform 3s 0.5s;
        will-change: opacity, transform;
        opacity: 1;
        transform: none;
    }
    &-button {
        min-width: 210px;
        padding: 18px 24px 17px 24px;
        display: inline-block;
        text-decoration: none;
        font-weight: bold;
        background: #fff;
        color: #000;
        border-radius: 10px;
        text-align: center;
        box-sizing: border-box;
        margin-top: 48px;
        opacity: 0;
        @media screen and (max-width: 900px) {
            padding: 16px 24px 15px 24px;
        }
        &:hover {
            transform: scale(1.0625);
            box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
        }
        &:active {
            transform: scale(0.99);
        }
    }
    body.loaded &-button {
        transition: opacity 2s 2s, transform 0.3s, box-shadow 0.3s;
        will-change: opacity, transform, box-shadow;
        opacity: 1;
    }
    &-trusted {
        margin-bottom: 0;
        position: relative;
        z-index: 4;
        &-title {
            font-size: 20px;
            opacity: 0;
            @media screen and (max-width: 900px) {
                font-size: 12px;
            }
        }
        body.loaded &-title {
            transition: opacity 2s 3s;
            will-change: opacity;
            opacity: 1;
        }
        &-logos {
            margin-top: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            &-item {
                margin-right: 48px;
                display: block;
                &:last-child {
                    margin-right: 0;
                }
                &-image {
                    display: block;
                }
                @media screen and (max-width: 900px) {
                    margin-right: 16px;
                    &:nth-child(1) &-image {
                        width: 42px;
                        height: auto;
                    }
                    &:nth-child(2) &-image {
                        width: 94px;
                        height: auto;
                    }
                    &:nth-child(3) &-image {
                        width: 90px;
                        height: auto;
                    }
                    &:nth-child(4) &-image {
                        width: 73px;
                        height: auto;
                    }
                    &:nth-child(5) &-image {
                        width: 67px;
                        height: auto;
                        display: none;
                    }
                }
            }
        }
        body.loaded &-logos {
            transition: opacity 2s 3.5s;
            will-change: opacity;
            opacity: 1;
        }
    }
}

.start {
    position: relative;
    padding-top: 200px;
    background: #08022f;
    @media screen and (max-width: 900px) {
        padding-top: 0;
        overflow: hidden;
    }
    &-hand {
        position: absolute;
        width: 50%;
        right: 60%;
        bottom: 60%;
        opacity: 0;
        animation: float 10s ease-in-out infinite;
        transform: translateX(-10%) rotate(-5deg);
        @media screen and (max-width: 900px) {
            left: -40px;
            top: 10px;
            width: 160px;
        }
    }
    body.loaded &-hand {
        transition: opacity 1s 3.5s, transform 1s 3.5s;
        will-change: opacity, transform;
        opacity: 1;
        transform: none;
    }
    &-bg {
        position: absolute;
        width: 100%;
        max-width: 1200px;
        height: 900px;
        left: -144px;
        right: 0;
        margin: auto;
        top: 200px;
        z-index: 1;
        @media screen and (max-width: 900px) {
            height: 500px;
            left: -230px;
            width: 832px;
            top: 60px;
        }
    }
    &-inner {
        max-width: 1200px;
        padding: 100px 16px 55px;
        margin: auto;
        display: grid;
        grid-template-columns: 480px auto;
        align-items: center;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 900px) {
            grid-template-columns: none;
            padding-top: 127px;
        }
    }
    &-wrapper {
        overflow: hidden;
    }
    &-text {
        padding-top: 120px;
        @media screen and (max-width: 900px) {
            grid-row: 2;
            padding-top: 64px;
        }
        &-title {
            font-weight: 400;
            font-size: 52px;
            line-height: 62px;
            text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
            @media screen and (max-width: 900px) {
                font-size: 28px;
                line-height: 120%;
                text-align: center;
            }
        }
        &-subtitle {
            font-size: 20px;
            margin-top: 24px;
            max-width: 400px;
            @media screen and (max-width: 900px) {
                max-width: none;
                font-size: 16px;
                text-align: center;
            }
        }
        &-button {
            width: 210px;
            padding: 18px 24px 17px 24px;
            display: block;
            text-decoration: none;
            font-weight: bold;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            margin-top: 34px;
            will-change: transform, box-shadow;
            transition: transform 0.3s, box-shadow 0.3s;
            @media screen and (max-width: 900px) {
                margin: 48px auto 0;
                padding: 16px 24px 15px 24px;
            }
            &:hover {
                transform: scale(1.0625);
                box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
            }
            &:active {
                transform: scale(0.99);
            }
        }
    }
    &-portal {
        height: 655px;
        position: relative;
        left: -70px;
        @media screen and (max-width: 900px) {
            height: 380px;
            margin-left: -20px;
        }
        @keyframes portal-gradient {
            0% {
                background-position: 0 center;
            }
            50% {
                background-position: 100% center;
            }
            100% {
                background-position: 0 center;
            }
        }
        &-gradient {
            width: 110px;
            height: 400px;
            position: absolute;
            top: 120px;
            left: 250px;
            // background: url("../assets/images/ring-gradient.jpg") no-repeat left center;
            background-size: auto 100%;
            border-radius: 100%;
            animation: portal-gradient 30s infinite;
            @media screen and (max-width: 900px) {
                height: 258px;
                width: 75px;
                top: 80px;
                left: 42px;
            }
        }
        &-back {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        @keyframes shine {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 1;
            }
            100% {
                opacity: 0;
            }
        }
        &-shine {
            height: 100%;
            position: absolute;
            top: 0;
            left: -150px;
            animation: shine 2s infinite;
        }
        @keyframes marquee {
            0% {
                transform: translateX(-50%);
            }
            100% {
                transform: translateX(0);
            }
        }
        &-nfts {
            display: flex;
            height: 230px;
            top: 195px;
            animation: marquee 30s infinite linear;
            position: absolute;
            @media screen and (max-width: 900px) {
                height: 142px;
                top: 110px;
            }
            &-wrapper {
                position: absolute;
                top: 0;
                left: 120px;
                overflow: hidden;
                width: 1500px;
                height: 630px;
                @media screen and (max-width: 900px) {
                    height: 396px;
                    width: 1000px;
                    left: 40px;
                }
            }
            &-item {
                height: 100%;
                width: 174px;
                margin-right: 16px;
                display: block;
                @media screen and (max-width: 900px) {
                    width: 114px;
                }
            }
        }
        &-front {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

.demo {
    background: linear-gradient(180deg, #08022f 33%, #000000 100%);
    overflow: hidden;
    position: relative;
    &-bg {
        position: absolute;
        /*left: 50%;
    transform: translateX(-50%);*/
        left: -250px;
        right: -250px;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 1953px;
        max-height: 100%;
    }
    &-inner {
        padding: 120px 16px 95px;
        margin: 0 auto;
        max-width: 1026px;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 900px) {
            padding-bottom: 55px;
        }
    }
    &-title {
        font-weight: 400;
        font-size: 52px;
        line-height: 62px;
        text-align: center;
        text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
        max-width: 780px;
        margin: auto;
        position: relative;
        z-index: 3;
        @media screen and (max-width: 900px) {
            font-size: 28px;
            line-height: 120%;
            text-align: center;
        }
    }
    &-subtitle {
        font-size: 20px;
        margin: 24px auto 0;
        max-width: 550px;
        text-align: center;
        position: relative;
        z-index: 3;
        @media screen and (max-width: 900px) {
            font-size: 16px;
            text-align: center;
        }
    }
    &-video {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
        position: relative;
        z-index: 1;
        &-wrapper {
            padding: 16px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 10px;
            margin: 76px auto 0;
            position: relative;
            background: #000;
            @media screen and (max-width: 900px) {
                width: 100%;
                position: relative;
                left: -16px;
                margin: 32px auto 0;
            }
        }
        &-bg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: block;
            filter: blur(10px);
            border-radius: 2px;
        }
        &-poster {
            display: block;
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }
    @keyframes float {
        0% {
            transform: translatey(0px);
        }
        50% {
            transform: translatey(-20px);
        }
        100% {
            transform: translatey(0px);
        }
    }
    &-polygon {
        position: absolute;
        left: -100px;
        top: 20px;
        will-change: transform;
        animation: float 10s ease-in-out infinite;
        z-index: 2;
        @media screen and (max-width: 900px) {
            height: 100px;
            width: auto;
            left: -50px;
        }
    }
    &-eth {
        position: absolute;
        right: -100px;
        rotate: 15deg;
        top: 156px;
        will-change: transform;
        animation: float 7s ease-in-out infinite;
        z-index: -1;
        @media screen and (max-width: 900px) {
            height: 50px;
            width: auto;
            right: -30px;
            top: 290px;
        }
    }
    &-card {
        position: absolute;
        width: 341px;
        height: auto;
        left: -244px;
        bottom: -5px;
        animation: float 8s ease-in-out infinite;
        z-index: 2;
        @media screen and (max-width: 900px) {
            height: 120px;
            width: auto;
            left: -30px;
            bottom: 0;
        }
    }
}

.secondary {
    position: relative;
    &-inner {
        max-width: 1200px;
        margin: auto;
        padding: 95px 16px 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        align-items: center;
        @media screen and (max-width: 900px) {
            grid-template-columns: none;
            padding-top: 120px;
        }
    }
    &-text {
        position: relative;
        z-index: 3;
        @media screen and (max-width: 600px) {
            display: flex;
            flex-direction: column;
            align-items: center; /* This centers all children, including the button */
        }
        &-title {
            font-weight: 400;
            font-size: 52px;
            line-height: 62px;
            text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
            max-width: 470px;
            @media screen and (max-width: 900px) {
                font-size: 28px;
                line-height: 120%;
                text-align: center;
            }
        }
        &-subtitle {
            font-size: 20px;
            margin-top: 24px;
            max-width: 420px;
            @media screen and (max-width: 900px) {
                font-size: 16px;
                text-align: center;
            }
        }
    }
    &-boat {
        display: block;
        width: 100%;
        height: auto;
        animation: float 8s ease-in-out infinite;
        &-wrapper {
            position: relative;
        }
    }
    &-ivan {
        display: block;
        width: 100%;
        height: auto;
        &-wrapper {
            position: relative;
            overflow: hidden;
        }
    }
    .rotate360 {
        transition: transform 1s;
        transform: rotate(360deg);
    }
    @keyframes fish1 {
        0% {
            transform: rotate(-30deg);
        }
        20% {
            bottom: 40%;
        }
        30% {
            bottom: 41%;
            transform: rotate(30deg);
        }
        70% {
            bottom: -100px;
            transform: rotate(50deg);
        }
    }
    @keyframes fish2 {
        0% {
            transform: rotate(-30deg);
        }
        20% {
            bottom: 35%;
        }
        30% {
            bottom: 36%;
            transform: rotate(30deg);
        }
        70% {
            bottom: -100px;
            transform: rotate(50deg);
        }
    }
    @keyframes fish3 {
        0% {
            transform: rotate(-30deg);
        }
        20% {
            bottom: 53%;
        }
        30% {
            bottom: 54%;
            transform: rotate(30deg);
        }
        70% {
            bottom: -100px;
            transform: rotate(50deg);
        }
    }
    @keyframes fish4 {
        0% {
            transform: rotate(-30deg);
        }
        20% {
            bottom: 47%;
        }
        30% {
            bottom: 48%;
            transform: rotate(30deg);
        }
        70% {
            bottom: -100px;
            transform: rotate(50deg);
        }
    }
    &-opensea {
        position: absolute;
        left: 0;
        bottom: -100px;
        transition: bottom 1s, transform 1s;
        will-change: transform, bottom;
        transform-origin: 50% 50%;
        animation: fish1 3s ease-in-out infinite;
        @media screen and (max-width: 600px) {
            width: 45px;
            height: auto;
        }
    }
    &-binance {
        position: absolute;
        left: 30%;
        bottom: -100px;
        will-change: transform, top;
        transform-origin: 50% 50%;
        animation: fish2 3.5s ease-in-out infinite;
        animation-delay: 1s;
        @media screen and (max-width: 600px) {
            width: 43px;
            height: auto;
        }
    }
    &-rarible {
        position: absolute;
        right: 20%;
        bottom: -100px;
        will-change: transform, top;
        transform-origin: 50% 50%;
        animation: fish3 3.5s ease-in-out infinite;
        animation-delay: 3s;
        @media screen and (max-width: 600px) {
            width: 45px;
            height: auto;
        }
    }
    &-superrare {
        position: absolute;
        right: 0;
        bottom: -100px;
        will-change: transform, top;
        transform-origin: 50% 50%;
        animation: fish4 3s ease-in-out infinite;
        animation-delay: 4s;
        @media screen and (max-width: 600px) {
            width: 40px;
            height: auto;
        }
    }
    &-marketplace:not(.jumping) {
        animation-play-state: paused;
    }
    &-wave {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 2;
        display: block;
        @media screen and (max-width: 900px) {
            width: 900px;
            left: auto;
            right: 0;
            max-width: 150%;
            height: auto;
        }
    }
}

.also {
    background: linear-gradient(180deg, #1a0e6a 33%, #08022f 100%);
    &-inner {
        max-width: 1200px;
        margin: auto;
        padding: 100px 16px;
        @media screen and (max-width: 900px) {
            padding: 120px 16px 55px;
            display: flex;
            flex-direction: column;
            align-items: center; /* This centers all children, including the button */
            text-align: center;
        }
    }
    &-title {
        font-weight: 700;
        font-size: 52px;
        line-height: 62px;
        @media screen and (max-width: 900px) {
            font-size: 28px;
            line-height: 120%;
        }
    }
    &-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 32px;
        row-gap: 48px;
        margin-top: 48px;
        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr;
        }
        &-item {
            &-icon {
                background: rgba(255, 255, 255, 0.2);
                border-radius: 100%;
                padding: 10px;
            }
            &:hover &-icon {
                background: #000;
            }
            &-title {
                margin-top: 14px;
                font-weight: 700;
                font-size: 24px;
                line-height: 45px;
                letter-spacing: 0.015em;
            }
            &-subtitle {
                margin-top: 5px;
                max-width: 236px;
                @media screen and (max-width: 900px) {
                    max-width: none;
                }
            }
        }
    }
}

.collections {
    &-inner {
        max-width: 1200px;
        margin: auto;
        padding: 100px 16px;
        @media screen and (max-width: 900px) {
            padding: 120px 16px 55px;
        }
    }
    &-caption {
        font-weight: 700;
        font-size: 28px; /* Changed font size for subtitle */
        line-height: 120%;
        text-align: center;
    }
    &-title {
        color: $text-color;
        font-weight: 800;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        max-width: 760px;
        margin: 0 auto;
        @media screen and (max-width: 1100px) {
            font-size: 24px;
            line-height: 32px;
            max-width: 100%;
            text-align: center;
        }
    }
    &-subtitle {
        font-size: 20px;
        margin: 24px auto 0;
        max-width: 550px;
        text-align: center;
        position: relative;
        z-index: 3;
        @media screen and (max-width: 900px) {
            font-size: 16px;
            text-align: center;
        }
    }

    &-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 32px;
        row-gap: 48px;
        margin-top: 48px;
        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }
        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;

            a {
                flex-grow: 1;
            }

            &-image {
                width: 100%;
                height: auto;
                border-radius: 4%;
                padding: 10px;
            }
            &:hover &-image {
                scale: 102%;
                transition: 1s ease-in-out;
            }
            &-caption {
                margin-top: 10px;
                text-align: center;
            }
        }
    }
}

.calc {
    position: relative;
    overflow: hidden;
    padding-top: 156px;
    @media screen and (max-width: 900px) {
        padding-top: 0;
    }
    &-bg {
        position: absolute;
        left: 0;
        right: -125px;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 1617px;
        max-height: 100%;
        @media screen and (max-width: 900px) {
            height: 100%;
            width: 200%;
            left: -50%;
            right: auto;
        }
    }
    &-inner {
        padding: 120px 16px 210px;
        margin: auto;
        position: relative;
        max-width: 820px;
        @media screen and (max-width: 900px) {
            padding: 120px 16px 55px;
        }
    }
    &-calc {
        position: absolute;
        left: -156px;
        top: 81px;
        will-change: transform;
        animation: float 7s ease-in-out infinite;
        @media screen and (max-width: 900px) {
            top: 20px;
        }
    }
    &-dollar {
        position: absolute;
        right: -124px;
        bottom: 55px;
        will-change: transform;
        animation: float 10s ease-in-out infinite;
        @media screen and (max-width: 900px) {
            bottom: 0;
            height: 250px;
            right: 20px;
        }
    }
    &-title {
        font-weight: 700;
        font-size: 52px;
        line-height: 62px;
        text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
        margin: auto;
        text-align: center;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 900px) {
            font-size: 28px;
            line-height: 120%;
        }
    }
    &-subtitle {
        margin: 24px auto 0;
        font-size: 20px;
        text-align: center;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 900px) {
            font-size: 16px;
        }
    }
    &-form {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 32px;
        margin-top: 64px;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 900px) {
            margin-top: 48px;
            grid-template-columns: 1fr;
        }
        &-field {
            background: transparent;
            border: none;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            outline: none !important;
            border-radius: 0;
            font-family: $text-font;
            font-weight: 700;
            font-size: $text-size;
            color: $text-color;
            text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.25);
            padding: 9px 0;
            margin-top: 6px;
            display: block;
            width: 100%;
            box-sizing: border-box;
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
            &:focus {
                border-color: #fff;
            }
            &.dollar:before {
                content: "$";
            }
            &-wrapper {
                max-width: 210px;
                @media screen and (max-width: 900px) {
                    max-width: none;
                }
            }
        }
    }
    &-result {
        margin: 80px auto 0;
        text-align: center;
        position: relative;
        z-index: 1;
        &-value {
            margin-top: 7px;
            font-weight: 700;
            font-size: 40px;
            line-height: 43px;
            text-shadow: 4px 4px 0 rgba(0, 0, 0, 0.25);
        }
        &-fee {
            font-style: italic;
            margin-top: 5px;
        }
    }
}

.request {
    overflow: hidden;
    position: relative;
    &-inner {
        max-width: 1200px;
        margin: auto;
        padding: 150px 66px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 32px;
        align-items: center;
        position: relative;
        @media screen and (max-width: 900px) {
            grid-template-columns: 1fr;
            padding: 120px 16px 0;
        }
    }
    &-bg {
        position: absolute;
        right: 0;
        left: -600px;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 2103px;
        max-height: 100%;
    }
    &-form {
        position: relative;
        @media screen and (max-width: 900px) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            height: calc(var(--vh, 1vh) * 100);
            overflow: scroll;
            transform: translateX(-100%);
            transition: transform 0.3s;
            z-index: 20;
            background: #fff;
            padding: 56px 16px 32px;
            box-sizing: border-box;
            display: none;
            &.visible {
                transform: translateX(0);
            }
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: -50px;
            right: -50px;
            top: -50px;
            bottom: -50px;
            border-radius: 10px;
            background: #fff;
            z-index: 1;
            @media screen and (max-width: 900px) {
                display: none;
            }
        }
        & > * {
            position: relative;
            z-index: 2;
        }
        &-close {
            position: absolute;
            right: 8px;
            top: 8px;
            cursor: pointer;
            &:active {
                transform: scale(0.9);
            }
        }
        &-title {
            font-weight: 700;
            font-size: 40px;
            line-height: 53px;
            color: #000000;
            max-width: 480px;
            @media screen and (max-width: 900px) {
                font-size: 28px;
                line-height: 120%;
            }
        }
        &-fieldset {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 32px;
            row-gap: 20px;
            border: none;
            padding: 0;
            margin: 42px 0 0 0;
            @media screen and (max-width: 600px) {
                grid-template-columns: 1fr;
                margin-top: 32px;
            }
        }
        &-input {
            background: transparent;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            outline: none !important;
            padding: 12px 0;
            font-family: $text-font;
            font-size: $text-size;
            color: #000;
            &::placeholder {
                color: #000;
            }
            &:focus {
                border-color: #000;
            }
        }
        &-label {
            color: #000;
            margin-top: 38px;
            display: block;
        }
        &-textarea {
            background: transparent;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            outline: none !important;
            font-family: $text-font;
            font-size: $text-size;
            color: #000;
            display: block;
            width: 100%;
            margin-top: 12px;
            padding: 12px;
            box-sizing: border-box;
            &:focus {
                border-color: #000;
            }
        }
        &-button {
            width: 210px;
            padding: 18px 24px 17px 24px;
            display: inline-block;
            vertical-align: middle;
            text-decoration: none;
            font-weight: bold;
            background: #000;
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            margin-top: 38px;
            border: none;
            outline: none !important;
            color: #fff;
            will-change: transform, box-shadow;
            transition: transform 0.3s, box-shadow 0.3s;
            cursor: pointer;
            @media screen and (max-width: 900px) {
                display: block;
            }
            @media screen and (max-width: 600px) {
                margin: 32px auto 0;
            }
            &:not(:disabled):hover {
                transform: scale(1.0625);
                box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
            }
            &:not(:disabled):active {
                transform: scale(0.99);
            }
            &:disabled {
                background: rgba(0, 0, 0, 0.2);
            }
        }
        &-sent {
            display: none;
            vertical-align: middle;
            margin: 38px 0 0 32px;
            color: #000;
            @media screen and (max-width: 600px) {
                margin: 16px 0 0;
            }
            &.visible {
                display: inline-block;
            }
        }
    }
    @media screen and (max-width: 900px) {
        body.loaded &-form {
            display: block;
        }
    }
    &-text {
        padding-left: 132px;
        @media screen and (max-width: 1300px) {
            padding-left: 64px;
        }
        @media screen and (max-width: 900px) {
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
        &-title {
            font-size: 52px;
            line-height: 62px;
            @media screen and (max-width: 900px) {
                font-size: 28px;
                line-height: 120%;
            }
        }
        &-list {
            margin: 24px 0 0 0;
            padding: 0;
            list-style: none;
            &-item {
                margin: 6px 0;
                padding: 0 0 0 32px;
                background: url("../assets/images/icon-bullet.svg") no-repeat
                    left top 9px;
                font-size: 20px;
                @media screen and (max-width: 900px) {
                    font-size: 16px;
                    margin: 12px 0;
                }
            }
        }
        &-button {
            width: 210px;
            padding: 18px 24px 17px 24px;
            display: block;
            text-decoration: none;
            font-weight: bold;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            margin: 48px auto 0; /* This centers the button on screens wider than 900px */
            will-change: transform, box-shadow;
            transition: transform 0.3s, box-shadow 0.3s;
            @media screen and (max-width: 900px) {
                margin: 48px 0 0 0; /* Adjusted for mobile to align the button to the left */
                padding: 16px 24px 15px 24px;
            }
            &:hover {
                transform: scale(1.0625);
                box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
            }
            &:active {
                transform: scale(0.99);
            }
        }
    }
}

.footer {
    max-width: 1200px;
    padding: 126px 16px 100px;
    margin: auto;
    display: grid;
    grid-template-columns: auto 276px;
    @media screen and (max-width: 900px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &-social {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-start;
        &-item {
            margin: 0;
            padding: 0;
            display: block;
            margin-right: 12px;
            &:last-child {
                margin-right: 0;
            }
            &-container {
                padding: 12px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 100%;
                display: block;
                &:hover {
                    background: rgba(255, 255, 255, 0.3);
                }
            }
            &-icon {
                display: block;
            }
        }
    }
    &-backers {
        @media screen and (max-width: 900px) {
            text-align: center;
        }
        &-title {
            margin-bottom: 12px;
            @media screen and (max-width: 900px) {
                margin-bottom: 16px;
                font-size: 12px;
                text-align: left;
            }
        }
        &-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media screen and (max-width: 900px) {
                justify-content: center;
            }
            &-item {
                margin-right: 32px;
                display: block;
                &:last-child {
                    margin-right: 0;
                }
                @media screen and (max-width: 900px) {
                    margin: 8px;
                    &:last-child {
                        margin-right: 8px;
                    }
                }
                &-logo {
                    display: block;
                    @media screen and (max-width: 1100px) {
                        max-height: 28px;
                        max-width: 116px;
                        width: auto;
                        height: auto;
                    }
                }
            }
        }
    }
    &-copyright {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        @media screen and (max-width: 900px) {
            align-items: flex-start;
            margin-top: 48px;
        }
        &-mintmade {
            display: block;
            &-logo {
                display: block;
            }
        }
        &-text {
            margin-top: 20px;
        }
    }
}

.d1100w,
.d900w {
    display: none;
}

@media screen and (max-width: 1100px) {
    .d1100w {
        display: block;
    }
}

@media screen and (max-width: 900px) {
    .d900w {
        display: block;
    }
}

.how {
    background: linear-gradient(180deg, #08022f 33%, #000000 100%);
    padding-top: 120px;
    box-sizing: border-box;
    position: relative;
    @media screen and (max-width: 1100px) {
        padding-top: 52px;
    }
    &-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
    }
    &-title {
        color: $text-color;
        font-weight: 800;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        max-width: 760px;
        margin: 0 auto;
        @media screen and (max-width: 1100px) {
            font-size: 24px;
            line-height: 32px;
            max-width: 100%;
            text-align: left;
        }
    }
    &-subtitle {
        color: $text-color;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        max-width: 595px;
        margin: 16px auto 0;
        @media screen and (max-width: 1100px) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 12px;
            max-width: 100%;
            text-align: left;
        }
    }
    &-scene {
        display: grid;
        grid-template-columns: 380px auto;
        gap: 59px;
        padding-top: 80px;
        width: 100%;
        @media screen and (max-width: 1100px) {
            display: block;
            padding-top: 40px;
        }
        &-steps {
            @media screen and (max-width: 1100px) {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 32px;
            }
            @media screen and (max-width: 860px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 32px;
            }
            @media screen and (max-width: 600px) {
                display: grid;
                grid-template-columns: 1fr;
                gap: 32px;
            }
            &-item {
                margin-bottom: 48px;
                @media screen and (max-width: 1100px) {
                    margin-bottom: 0;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                @media screen and (min-width: 1100px) {
                    &:not(:first-child) {
                        transform: translateY(150%);
                        opacity: 0;
                    }
                }
                &-header {
                    display: grid;
                    grid-template-columns: min-content auto;
                    gap: 24px;
                    align-items: center;
                    @media screen and (max-width: 1100px) {
                        gap: 16px;
                    }
                    &-num {
                        width: 48px;
                        height: 48px;
                        background: #003060;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 24px;
                        color: $text-color;
                    }
                    &-content {
                        &-suptitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: white;
                        }
                        &-title {
                            font-weight: 600;
                            font-size: 24px;
                            line-height: 32px;
                            color: $text-color;
                            @media screen and (max-width: 1100px) {
                                font-size: 20px;
                                line-height: 28px;
                            }
                            @media screen and (min-width: 1100px) {
                                &-emoji {
                                    opacity: 0;
                                    transform: translateX(-100%);
                                }
                            }
                        }
                    }
                }
                &-content {
                    margin-top: 24px;
                }
                @media screen and (min-width: 1100px) {
                    &:first-child {
                        transform: translateY(150%);
                    }
                    &:first-child &-header-num {
                        transform: translateX(7px) scale(1.3);
                        background: white;
                        color: black;
                    }
                    &:first-child &-header-content {
                        transform: translateX(14px);
                    }
                }
            }
        }
        &-screen {
            @media screen and (max-width: 1100px) {
                display: none;
            }
            &-container {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                background: #000;
                border: 1px solid #d9d9d9;
                position: relative;
                overflow: hidden;
                border-radius: 24px;
                padding: 24px;
            }

            &-inner {
                overflow: hidden;
                position: relative;
                border-radius: 16px;
                background: #fff;
            }

            &-item {
                width: 100%;
                height: auto;
                box-sizing: border-box;
                display: block;
                position: relative;
                z-index: 1;
                &:not(:first-child) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                }
            }
        }
    }
}

.why {
    padding: 120px 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1100px) {
        padding: 52px 0;
    }
    &:before {
        content: "";
        width: 100%;
        height: 292px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, #1b1b1b 0%, rgba(0, 0, 0, 0) 100%);
    }
    &-dots-pattern {
        position: absolute;
        top: -40px;
        left: 0;
        right: 0;
        width: 85%;
        height: auto;
        margin: auto;
    }
    &-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
        position: relative;
    }
    &-title {
        color: $text-color;
        font-weight: 800;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        max-width: 760px;
        margin: 0 auto;
        @media screen and (max-width: 1100px) {
            font-size: 24px;
            line-height: 32px;
            max-width: 100%;
            text-align: left;
        }
    }
    &-subtitle {
        color: $text-color;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        max-width: 693px;
        margin: 16px auto 0;
        @media screen and (max-width: 1100px) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 12px;
            max-width: 100%;
            text-align: left;
        }
    }
    &-scene {
        overflow: visible;
        margin: 0 auto;
        padding-top: 80px;
        @media screen and (max-width: 1100px) {
            width: calc(100% + 32px);
            margin-left: -16px;
            padding-top: 40px;
            overflow: scroll;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &-inner {
            display: inline-flex;
            flex-direction: row;
            overflow: visible;
        }
        &-item {
            width: 380px;
            height: 490px;
            background: #131313;
            padding: 82px 43px;
            box-sizing: border-box;
            position: relative;
            overflow: hidden;
            margin-right: 32px;
            @media screen and (min-width: 1100px) {
                transform: translateY(40px);
                opacity: 0;
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    opacity: 1;
                    transform: translateY(0);
                }
                &:nth-child(4) {
                    opacity: 0.6;
                    transform: translateY(20px);
                }
                &:nth-child(5) {
                    opacity: 0;
                    transform: translateY(40px);
                }
            }
            @media screen and (max-width: 1100px) {
                width: 273px;
                height: 398px;
                margin-right: 16px;
                padding: 60px 24px;
                &:first-child {
                    margin-left: 16px;
                }
            }
            &:before {
                content: "";
                width: 22px;
                height: 22px;
                border-radius: 100%;
                position: absolute;
                left: 30px;
                top: 30px;
                // background: white;
                @media screen and (max-width: 1100px) {
                    left: 20px;
                    top: 20px;
                }
            }
            &-num {
                font-weight: 700;
                font-size: 169px;
                line-height: 230px;
                color: #616161;
                position: absolute;
                left: -46px;
                bottom: -6px;
                @media screen and (max-width: 1100px) {
                    bottom: -58px;
                }
            }
            &-title {
                max-width: 230px;
                min-height: 64px;
                font-weight: 700;
                font-size: 24px;
                line-height: 32px;
                text-transform: uppercase;
                color: $text-color;
                position: relative;
                z-index: 1;
            }
            &-subtitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $text-color;
                margin-top: 30px;
                position: relative;
                z-index: 1;
                @media screen and (max-width: 1100px) {
                    margin-top: 20px;
                }
            }
        }
    }
}

.own {
    box-sizing: border-box;
    z-index: 1;
    @media screen and (max-width: 1100px) {
        padding-top: 52px;
    }
    // @media screen and (max-width: 860px) {
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100%;
    //     background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0));
    //     z-index: 1;
    //   }
    // }
    &-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
        position: relative;
        align-items: center;
        display: grid;
        grid-template-columns: 470px auto;
        @media screen and (max-width: 860px) {
            display: block;
        }
    }
    &-content {
        position: relative;
        z-index: 2;
        @media screen and (max-width: 860px) {
            padding-top: 290px;
        }
        &-title {
            font-weight: 800;
            font-size: 40px;
            line-height: 56px;
            color: $text-color;
            @media screen and (max-width: 1100px) {
                font-size: 24px;
                line-height: 32px;
            }
        }
        &-footer {
            display: flex;
            margin-top: 48px;
            align-items: center;
            @media screen and (max-width: 1100px) {
                margin-top: 40px;
            }
            @media screen and (max-width: 600px) {
                flex-direction: column;
                align-items: start;
            }
        }
        &-button {
            min-width: 210px;
            padding: 18px 24px 17px 24px;
            display: inline-block;
            text-decoration: none;
            font-weight: bold;
            background: #fff;
            color: #000;
            border-radius: 10px;
            text-align: center;
            box-sizing: border-box;
            opacity: 1;
            @media screen and (max-width: 900px) {
                padding: 16px 24px 15px 24px;
            }
            &:hover {
                transform: scale(1.0625);
                box-shadow: 0 20px 20px -10px rgba(0, 0, 0, 0.25);
            }
            &:active {
                transform: scale(0.99);
            }
        }
        &-checklist {
            margin: 0;
            padding: 0;
            list-style: none;
            color: #616161;
            transition: 0.3s color;
            @media screen and (max-width: 600px) {
                margin-top: 16px;
            }
            &.highlight {
                color: #fff;
            }
            &-item {
                display: flex;
                align-items: center;
                margin-top: 6px;
                &:first-child {
                    margin-top: 0;
                }
                &-icon {
                    margin-right: 12px;
                    transition: 0.3s fill;
                }
            }
            &.highlight &-item-icon {
                fill: white;
            }
        }
    }
    &-scene {
        width: 100%;
        position: relative;
        transform: translateX(96px);
        @media screen and (max-width: 1100px) {
            transform: none;
        }
        @media screen and (max-width: 860px) {
            position: absolute;
            width: 120%;
            max-width: 400px;
            left: 50%;
            top: -10%;
            transform: translateX(-50%);
        }
        &-image {
            width: 57.8%;
            height: auto;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            display: block;
            filter: blur(0);
            &#own-image-1 {
                width: 47.6%;
                position: absolute;
                @media screen and (max-width: 860px) {
                    left: -40%;
                    transform: rotate(-6deg);
                }
            }
            &#own-image-3 {
                width: 48.8%;
                position: absolute;
                @media screen and (max-width: 860px) {
                    left: 40%;
                    transform: rotate(6deg);
                }
            }
            &#own-image-4 {
                width: 100.8%;
                position: absolute;
                @media screen and (max-width: 860px) {
                    left: 40%;
                    transform: rotate(6deg);
                }
            }
            &#own-image-2 {
                transform: scale(0.9);
                @media screen and (max-width: 860px) {
                    transform: none;
                    box-shadow: 0px 0px 18px rgba(47, 44, 44, 0.7);
                }
            }
        }
    }
}

.who {
    padding-top: 120px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    @media screen and (max-width: 1100px) {
        padding-top: 52px;
    }
    &-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
        position: relative;
    }
    &-dots-pattern {
        width: 820px;
        height: auto;
        position: absolute;
        bottom: -130px;
        right: -130px;
    }
    &-title {
        color: $text-color;
        font-weight: 800;
        font-size: 40px;
        line-height: 56px;
        text-align: center;
        max-width: 760px;
        margin: 0 auto;
        @media screen and (max-width: 1100px) {
            font-size: 24px;
            line-height: 32px;
            text-align: left;
            max-width: none;
        }
        & .highlight {
            color: white;
        }
    }
    &-subtitle {
        color: $text-color;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        max-width: 795px;
        margin: 16px auto 0;
        @media screen and (max-width: 1100px) {
            font-size: 18px;
            line-height: 24px;
            margin-top: 12px;
            text-align: left;
            max-width: none;
        }
    }

    &-video {
        width: 100%;
        height: auto;
        margin-top: 80px;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 1100px) {
            margin-top: 40px;
        }
    }

    &-stats {
        overflow: visible;
        position: relative;
        z-index: 1;
        margin-top: 130px;
        @media screen and (max-width: 1100px) {
            margin-top: 52px;
            overflow: scroll;
            width: calc(100% + 32px);
            margin-left: -16px;
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
        &-inner {
            display: inline-flex;
            cursor: grab;
        }
        &-item {
            margin-right: 32px;
            display: grid;
            grid-template-columns: 283px auto;
            align-items: center;
            width: 770px;
            box-sizing: border-box;
            padding: 45px;
            border: 1px solid white;
            background-color: white 0.6;
            border-radius: 10px;
            &:last-child {
                margin-right: 0;
            }
            @media screen and (max-width: 1100px) {
                &:first-child {
                    margin-left: 16px;
                }
                &:last-child {
                    margin-right: 16px;
                }
            }
            @media screen and (max-width: 860px) {
                width: 273px;
                display: block;
                padding: 32px 16px;
                margin-right: 16px;
            }
            &-label {
                border-right: 1px solid #4f4f4f;
                @media screen and (max-width: 860px) {
                    border-right: 0;
                    border-bottom: 1px solid #4f4f4f;
                    padding-bottom: 14px;
                }
                &-count {
                    font-weight: 800;
                    font-size: 82px;
                    line-height: 130%;
                    color: white;
                    @media screen and (max-width: 860px) {
                        font-size: 64px;
                        line-height: 84px;
                    }
                }
                &-title {
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 32px;
                    color: $text-color;
                    margin-top: 32px;
                    padding-right: 40px;
                    @media screen and (max-width: 860px) {
                        font-size: 20px;
                        line-height: 28px;
                        margin-top: 8px;
                    }
                }
            }
            &-content {
                padding-left: 40px;
                @media screen and (max-width: 860px) {
                    padding-left: 0;
                    padding-top: 24px;
                }
                &-text {
                    & .highlight {
                        color: white;
                    }
                }
            }
            &-bar {
                width: 100%;
                background: #131313;
                border-radius: 27px;
                overflow: hidden;
                position: relative;
                margin-top: 54px;
                height: 2px;
                @media screen and (max-width: 1100px) {
                    display: none;
                }
                &-runner {
                    background: #616161;
                    border-radius: 27px;
                    width: 400px;
                    height: 100%;
                    @media screen and (max-width: 1100px) {
                        width: 97px;
                    }
                }
            }
        }
    }
}

.museum {
    padding: 100px 0 0;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1100px) {
        padding-top: 52px;
    }
    &:before {
        content: "";
        width: 100%;
        height: 113px;
        position: absolute;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, #1b1b1b 0%, rgba(0, 0, 0, 0) 100%);
        @media screen and (max-width: 860px) {
            display: none;
        }
    }
    &-inner {
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
        display: grid;
        grid-template-columns: auto 510px;
        gap: 70px;
        align-items: center;
        @media screen and (max-width: 860px) {
            display: block;
        }
    }
    &-image {
        width: 108.22%;
        transform: translateX(-8.22%);
        display: block;
        position: relative;
        z-index: 1;
        @media screen and (max-width: 860px) {
            width: 100%;
            max-width: 400px;
            transform: none;
            margin: 0 auto;
        }
    }
    &-content {
        position: relative;
        @media screen and (max-width: 860px) {
            padding-top: 24px;
        }
        &-dots-pattern {
            position: absolute;
            left: 122px;
            width: 543px;
            height: auto;
            margin: auto;
            top: 0;
            bottom: 0;
        }
        &-title {
            font-weight: 800;
            font-size: 40px;
            line-height: 56px;
            color: $text-color;
            @media screen and (max-width: 1100px) {
                font-size: 24px;
                line-height: 32px;
            }
        }
        &-subtitle {
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: $text-color;
            margin-top: 24px;
            @media screen and (max-width: 1100px) {
                font-size: 18px;
                line-height: 24px;
                margin-top: 12px;
            }
        }
        &-button {
            display: inline-flex;
            padding: 14px 24px;
            box-sizing: border-box;
            position: relative;
            background: transparent;
            color: white;
            border: 1px solid white;
            text-decoration: none;
            overflow: hidden;
            transition: 0.3s box-shadow;
            margin-top: 50px;
            @media screen and (max-width: 1100px) {
                margin-top: 32px;
            }
            @media screen and (max-width: 600px) {
                width: 100%;
                justify-content: center;
            }
            &:hover {
                box-shadow: 0 0 0 2px white;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(
                    110.65deg,
                    rgba(250, 191, 44, 0.16) 15.85%,
                    rgba(250, 191, 44, 0) 60.23%
                );
                transform: translate(-100%);
                transition: transform 0.3s;
            }
            &:hover:after {
                transform: translate(0);
            }
        }
    }
}

.button-secondary {
    display: inline-flex;
    padding: 14px 24px;
    box-sizing: border-box;
    position: relative;
    background: transparent;
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    text-decoration: none;
    overflow: hidden;
    transition: 0.3s box-shadow;
    margin-top: 50px;
    justify-content: center;
    align-items: center; /* Center content inside the button */
    /* Removed &:hover and &:after for brevity */

    @media screen and (max-width: 600px) {
        display: flex; /* Changed to flex to enable centering */
        margin: 50px auto 0; /* Adjust margin to auto for horizontal centering */
        width: auto; /* Adjust width as necessary */
    }
}

.caption {
    z-index: 4;
    position: absolute;
    bottom: 2%;
    left: 20%;
    text-shadow: 1px 1px 2px black;
    font-size: small;
    @media screen and (max-width: 900px) {
        bottom: -5px;
        left: 3%;
    }
}
.caption-ivan {
    font-weight: bold;
}

.legacy {
    background-color: #08022f;
    &-inner {
        max-width: 1200px;
        margin: auto;
        padding: 100px 16px;
        @media screen and (max-width: 900px) {
            padding: 120px 16px 55px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    &-logos {
        margin-top: 48px;
        display: flex;
        flex-wrap: wrap; /* Allow items to wrap */
        align-items: center;
        justify-content: space-around; /* Space items around for better distribution */
        gap: 20px;
        @media screen and (max-width: 900px) {
            justify-content: space-around; /* Center items for better alignment on small screens */
            gap: 10px;
        }
    }
    &-logos-item {
        flex: 0 1 auto; /* Allow flex items to grow and shrink but not beyond their auto size */
        max-width: 40%; /* Set maximum width to 40% of the parent container */
        margin: 0 5%; /* Add margins to help maintain spacing between items */
        @media screen and (max-width: 900px) {
            max-width: 40%; /* Ensure max-width is consistent on mobile */
            margin: 10px 0%; /* Adjust margin as needed for spacing */
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
